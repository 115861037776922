@import '~roboto-fontface/css/roboto/roboto-fontface.css';
@import '~roboto-fontface/css/roboto-slab/roboto-slab-fontface.css';
@import '~mdi/css/materialdesignicons.css';
@import '~material-icons/iconfont/material-icons.css';
@import '~material-icons/css/material-icons.css';
@import '~react-quill/dist/quill.snow.css';
@import './assets/animate.css';
@import './assets/flex-helper.css';
@import '~axios-progress-bar/dist/nprogress.css';
@import 'globals';


@include react-md-everything;

$default-font:'Source Sans Pro', 'Roboto', sans-serif;
$decorative-font:'Roboto Slab', 'Roboto', serif;
$body-font:'Open Sans', 'Roboto', serif !important;

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $default-font;
  background-color: #eee;
}

h1, h2, h3, h4, h5, h6{
  font-family: $default-font;
}


#root{
  width: 100%;
  height: 100%;
}

.decorative{
  font-family: $decorative-font !important;
}

.body-text{
  font-family: $body-font;
  font-weight: 400;
  > * {
    font-family: $body-font;
    font-weight: 400;
  }
}

.mpk-layout > * {
  min-width: 0px;
  min-height: 0px;
}

.md-divider {
  height: 1.2px ;
}

.iziToast {

  border-radius: none !important;
  &:after {
    border-radius: none !important;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4) !important;
  }
}

#nprogress .bar {
  background: rgb(76, 175, 80) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px rgb(76, 175, 80), 0 0 5px rgb(76, 175, 80) !important;
}

#nprogress .spinner-icon {
  border-top-color: rgb(76, 175, 80) !important;
  border-left-color: rgb(76, 175, 80) !important;
}
