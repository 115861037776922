@import 'globals';
@import '~react-mpk/styles/mpk';

$max-media-width:1024px;
$max-side-width:240px;

window, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

.mpk-step {
  overflow: inherit !important;
}

.tab-disabled {
  // background-color: #7f0000 !important;
  background-color: #12005e !important;
}

.md-dialog .md-data-table--responsive {
  overflow: inherit !important;
}

.md-dialog .react-swipeable-view-container > div {
  overflow-y: inherit !important;
}

.unscroll .md-dialog-content {
  overflow-y:inherit;
}

.mpk-sidebar {
  background-color: $md-primary-color;
}

// --------TABLE--------/
#mpk-inner-tbl > div {
  height: 100%;
}

.mpk-table-setting .md-grid {
  margin: 0 !important;
}

.md-table-column {
  min-width: 100px;
}

.md-data-table--responsive {
  overflow-y: auto;
}
// --------END OF TABLE--------/

// --------SIDEBAR--------/
.mpk-sidabar-menu .group .menu-item {
  text-align: left;
  height: auto !important;
  min-height: 40px;
}

.close-menu {
  color: white !important;
}
.mpk-tab-clear .md-tab--inactive {
  color: rgba(0, 0, 0, 0.72) !important;
}

.mpk-tab-flex {
  .md-tabs-content {
    flex: 1;

    .react-swipeable-view-container, .react-swipeable-view-container > div {
      height: 100% !important;
    }
  }
}

.md-dark-theme {
  .md-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .md-text--secondary {
    color: rgba(255, 255, 255, 0.54);
  }
}

.form .md-paper {
  width: inherit !important;
  height: inherit !important;
}

.md-menu--select-field .md-paper {
  width: inherit !important;
  height: inherit !important;
}

.md-list--menu-contained.md-paper{
  width: 100% !important;
}

.md-text-field-container {
  .md-btn--icon {
    position: absolute !important;
    height: 20px;
    width: 20px;
    color: inherit;
    top: 30px;
  }
}


.mpk-expansion-no-padding .md-panel-content {
  padding: 0 0px 16px;
}

.module-menu {
  position: absolute !important;
  top: -20px;
  left: 170px;
  z-index: 2;
  background: white !important;
}

.mpk-tab-white {
  .md-tab {
    color: rgba(0, 0, 0, 0.72);
  }
  .md-tab--inactive {
    color: rgba(0, 0, 0, 0.5);
  }
}

// -------- END OF SIDEBAR-------//

// -------- MISC ----------//
.mpk-progress {
  width: 100%;
}

.center-container{
  width: $max-media-width;
  margin:0 auto;
}

.side-container{
  width: $max-side-width;
}

.text-editor{
  border:thin solid $mpk-border-color-dark;
  padding:0 $mpk-padding-N;
}

.mpk-badge {
  border-radius: 4px;
  font-size: 10px;
  padding: 4px 10px;
  border: 2px solid black;
  font-weight: bold;
  margin: 4px;
  display: inline-block;
}
// --------END OF MISC --------//

@media screen and (max-width: $max-media-width){
  .center-container{
    width: 100%;
    padding:0 $mpk-padding-N
  }
}

@media screen and (min-width: 1025px) {
  .md-tab {
    min-width: 72px !important;
  }
}
